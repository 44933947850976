.benefits-section .section-heading {
  width: 65%;
}
.benefits-cards-wrapper {
  align-items: flex-end;
}
.benefits-card-inner {
  padding: 3rem 2.5rem;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out;
}
.benefits-card-inner h3 {
  font-size: 18px;
  font-weight: 600;
}
.benefits-card-inner p {
  font-size: 14px;
  font-weight: 400;
}
.second-card img,
.third-card img {
  width: fit-content;
}
.first-card,
.third-card {
  background: #4d4dfe;
}
.second-card {
  background: #fafcff;
  box-shadow: 0 0 7px #573bd34d;
}
.benefits-card-inner * {
  color: #fff;
}
.second-card h3 {
  color: #4d4dfe;
}
.second-card p {
  color: #6e6ee7;
}
.fourth-card {
  position: relative;
  z-index: 1;
  padding-bottom: 14.157rem;
  background: url(/src/assets/BenefitsSection/benefit-img4.svg);
  background-position: center;
  background-size: cover;
}

.fourth-card::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: #000;
  opacity: 0.35;
  z-index: -1;
  border-radius: 22px;
}
.benefits-card-inner:hover {
  transform: translateY(-20px);
}
@media (max-width: 1440px) {
  .benefits-section .section-heading {
    width: 80%;
  }
  .benefits-card {
    padding: 5px;
  }
}
@media (max-width: 1024px) {
  .benefits-cards-wrapper {
    align-items: normal;
    row-gap: 3rem;
  }
  .benefits-card {
    padding: 0 1rem;
  }
  .benefits-card-inner {
    height: 100%;
  }
}
