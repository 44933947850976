.integration-section .section-wrapper {
  background: #f7f9fc;
  border-radius: 32px;
  padding: 3.5rem;
}
.integration-toos-image {
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .integration-section .section-wrapper {
    padding: 0;
  }
  .integration-section .section-heading {
    padding: 3.5rem 2.5rem;
    margin: 0;
  }
}
