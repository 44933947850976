/* Banner  */
.banner-section {
  background: url(/src/assets/BannerSection/BannerBG.svg) no-repeat center
    center / cover;
  min-height: 1030px;
}
.banner-content-wrapper {
  text-align: left !important;
  padding-top: 20rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.left-side {
  width: 51%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.right-side {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.banner-content-wrapper h2 {
  font-size: 1.6rem;
  font-weight: 700;
  color: #ffffff;
}
.banner-content-wrapper h1 {
  font-size: 5.4rem;
  color: #fff;
  width: 85%;
  line-height: 63px;
}
.banner-content-wrapper p {
  color: #ffffff;
  font-size: 1.8rem;
  width: 90%;
}

.banner-button-wrapper {
  margin-top: 5rem;
}
.banner-button {
  background: #ffffff;
  border: none;
  border-radius: 38px;
  display: inline-block;
  min-height: 48px;
  padding: 1.5rem 5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  cursor: pointer;
}
.banner-button p {
  color: #4d4dfe !important;
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0;
  width: 100% !important;
}
.banner-cards-wrapper {
  margin: 9rem 0;
}
.banner-card-inner {
  background: #fbfbff;
  border: 1px solid #e3eeff;
  border-radius: 22px;
  padding: 2rem;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  cursor: default;
}
.banner-card-inner img {
  width: 40px;
}
.banner-card-inner:hover {
  box-shadow: 0px 4px 16px 0px #33339640;
  transform: translateY(-5px);
}
.banner-card-inner h2 {
  font-size: 2.2rem;
  margin: 1.5rem 0;
  color: #232233;
}
.banner-card-inner p {
  color: #6c6c72;
}

@media (max-width: 1024px) {
  .banner-content-wrapper {
    width: 100%;
    flex-direction: column;
    padding-top: 15rem;
    row-gap: 8rem;
  }
  .left-side {
    width: 100%;
  }
  .banner-content-wrapper h1 {
    width: 100%;
  }
  .banner-button-wrapper {
    margin-top: 3rem;
  }
  .right-side {
    width: 100%;
  }
  .banner-cards-wrapper {
    width: 100%;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .left-side {
  }
  .right-side {
    width: 48%;
  }
}
@media (max-width: 768px) {
  .banner-cards-wrapper {
    gap: 2.5rem;
  }
  .elixir-logo img {
    width: 200px;
  }
}
@media (max-width: 450px) {
  .banner-content-wrapper h1 {
    font-size: 3.1rem;
    line-height: 4.5rem;
  }
  .banner-content-wrapper p {
    font-size: 13px;
  }
  .banner-button {
    padding: 1.5rem 2rem;
  }
}
