.heading-tag {
  margin-bottom: 2rem;
}
.heading-tag p {
  font-size: 2.8rem;
  color: #4d4dfe;
}
.how-to-grow-content-wrapper {
  display: flex;
  align-items: center;
  gap: 7rem;
  width: 65%;
  margin: 0 auto;
}

.section-wrapper .section-heading {
  text-align: center;
  margin: 0 auto 5rem auto;
}
.section-wrapper .section-heading h2 {
  color: #262626;
}
.roi-card {
  width: 30%;
  position: relative;
}
.roi-card:not(:nth-child(3))::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  background-image: url(/src/assets/HowtoGrowSection/roi-divider.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.roi-card span {
  font-size: 4rem;
  font-weight: 800;
  color: #000000;
}
.roi-card h3 {
  font-size: 2rem;
  color: #000000;
}

@media (max-width: 1440px) {
  .how-to-grow-content-wrapper {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .how-to-grow-content-wrapper {
    width: 100%;
    flex-direction: column;
  }
  .roi-card {
    width: 100%;
  }
  .roi-card::after {
    background: none !important;
  }
}
@media (max-width: 400px) {
  .heading-tag p {
    font-size: 2rem !important;
  }
  .section-wrapper .section-heading h2 {
    font-size: 3rem;
  }
  .section-wrapper .section-heading p {
    font-size: 13px;
  }
}
